/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { useMemo } from 'react'

/**
 * Takes validation configurations and returns numeric constraints.
 * @param {boolean} hasDecimalValue - Enable decimal validation
 * @param {string} decimalValue - Number of decimal places
 * @param {boolean} hasMinValue - Enable minimum validation
 * @param {string} minValue - Minimum value
 * @param {boolean} hasMaxValue - Enable maximum validation
 * @param {string} maxValue - Maximum value
 */
export function useNumericalConstraints (
    hasDecimalValue,
    decimalValue,

    hasMinValue,
    minValue,

    hasMaxValue,
    maxValue,
) {

    const constraints = useMemo(
        () => {
            const min = hasMinValue && minValue ? Number(minValue) : undefined;
            const max = hasMaxValue && maxValue ? Number(maxValue) : undefined;
            const hasDecimalValueNumber = !!decimalValue; // is likely a string
            const decimals = hasDecimalValue && hasDecimalValueNumber ? Number(decimalValue) : 0;

            const intermediate = Number(10**(0-decimals)); // For instance: 10 to the power of -4 or 0.0001
            const incrementStep = intermediate.toPrecision(1);
            
            return {
                min,
                max,
                decimals,
                incrementStep
            }
        },
        [decimalValue, hasDecimalValue, hasMaxValue, hasMinValue, maxValue, minValue]
    )

    return constraints
}
