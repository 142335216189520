/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import cn from 'clsx'
import { useNumericalConstraints } from 'cms/forms/hooks/use-numerical-constraints'
import { useNumericalValidator } from 'cms/forms/hooks/use-numerical-validator'
import { useEffect, useState } from 'react'

/**
 * @param {object} props
 * @param {NumericalSubQuestion} props.subQuestion
 * @param {boolean} props.isTouched
 * @param {number=} props.defaultValue
 * @param {(value: string) => void} props.onChange
 * @param {(error: string) => void} props.onError
 */
export function GridNumerical (props) {

    const { subQuestion, onChange, isTouched, onError, defaultValue } = props
    const {
        required,

        // min
        hasMinValue,
        minValue,

        // max
        hasMaxValue,
        maxValue,

        // decimals
        hasDecimalValue: _hasDecimalValue,
        decimalValue: _decimalValue,
        hasDemicalValue,
        demicalValue,

        // hasPlaceholder,
        // hasPlaceholderText

    } = subQuestion

    // Fixes for a typo in data
    const hasDecimalValue = hasDemicalValue ? hasDemicalValue : _hasDecimalValue
    const decimalValue = demicalValue ? demicalValue : _decimalValue

    const { min, max, decimals, incrementStep } = useNumericalConstraints(
        hasDecimalValue,
        decimalValue,
        hasMinValue,
        minValue,
        hasMaxValue,
        maxValue,
    )
    // const formatter = new Intl.NumberFormat('en-US', {
    //     minimumFractionDigits: decimalValue,
    //     maximumFractionDigits: decimalValue,
    // });
    // const precision = Number(decimalValue) || 0
    // const defaultPlaceholder = precision > 0 ? formatter.format(0) : 1
    // const placeholder = precision > 0 ? `e.g. ${defaultPlaceholder}`: `e.g. 1`
    // const placeholder = hasPlaceholder ? hasPlaceholderText : ""

    const [value, setValue] = useState(defaultValue)

    useEffect(
        () => {
            // ignore until we actually edit this input
            if (value !== undefined) {
                const numericalValue = Number(value)
                onChange(numericalValue)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value]
    )

    const error = useNumericalValidator(
        min,
        max,
        decimals,
        required,
        value
    )

    useEffect(
        () => {
            onError(error)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error]
    )

    const className = cn({
        'form-field-error-input': error && isTouched
    })

    const onChangeHandler = (event) => {
        setValue(event.target.value)
    }

    return (
        <input
            className={className}
            type="number"
            defaultValue={defaultValue}
            // placeholder={placeholder}
            min={min}
            max={max}
            step={incrementStep}
            onChange={onChangeHandler}
        />
    )
}
