/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { getForm } from 'cms/forms/utils/get-form'
import { isArray } from 'cms/utils/empty-exists'
import { useRef } from 'react'
import { getValidatableQuestions } from './get-validatable-questions'
import { useFormContext } from './use-form-context'
import { validateQuestions } from './validate-questions'

const useForm = () => {

    const { state: FormState, dispatch: FormDispatch } = useFormContext()
    const { token } = useAuth()

    /**
     * Tracks if we're already loading the form
     */
    const isLoading = useRef(false)

    const setLoading = () => {
        FormDispatch({
            type: 'SET_LOADING',
            loading: isLoading.current
        })
    }

    /**
     * @param {string} guid - The form ID
     * @param {boolean=} reset - Whether to reset or not
     */
    const getCurrentForm = async (guid, reset = false) => {
        // prevent multiple requests from being executed at the same time
        if (isLoading.current) {
            return
        }

        isLoading.current = true
        setLoading()

        const currentForm = await getForm(token, guid)

        if (currentForm) {
            FormDispatch({
                type: 'UPDATE_CURRENT_FORM',
                schemaForm: currentForm,
                guid,
                reset
            })
        }

        isLoading.current = false
        setLoading()
    }

    /**
     * @param {any} newValue
     * @param {FormQuestion} question
     */
    const onInputChange = (newValue, question) => {
        FormDispatch({
            type: 'UPDATE_QUESTION_VALUE',
            guid: question.guid,
            newValue,
        })
    }

    /**
     * @param {string} guid Question guid
     * @param {QuestionOptionRule[]} newRules
     */
    const updateRules = (guid, newRules) => {
        FormDispatch({
            type: 'UPDATE_QUESTION_RULES',
            guid,
            newRules,
        })
    }

    /**
     * Merges labels question's existing labels.
     * @param {string} guid
     * @param {Record<string, string>} labelDef
     */
    const saveLabels = (guid, labelDef) => {

        FormDispatch({
            type: 'SET_QUESTION_LABELS',
            guid,
            labelDef
        })
    }

    /**
     * Sets one or many errors for a given question.
     * This overwrites any previous errors.
     * @param {string} guid - Question guid
     * @param {string | any[] | null=} rawError - Error(s) to set; setting null or undefined removes errors
     */
    const setQuestionError = (guid, rawError) => {
        // default null or undefined values to an empty array
        const error = rawError ?? []

        // force errors to be an array to simplify validation
        let errors = isArray(error) ? error : [error]

        FormDispatch({
            type: 'SET_QUESTION_ERROR',
            guid,
            errors
        })
    }

    const resetForm = () => {
        return getCurrentForm(FormState.schemaForm.form_id, true)
    }

    const nextStep = () => {
        const step = FormState.activeStep + 1
        FormDispatch({ type: 'SET_STEP', step })
    }

    const prevStep = () => {
        const step = FormState.activeStep - 1
        FormDispatch({ type: 'SET_STEP', step })
    }

    /**
     * Determine validatable questions, validate and record them.
     * @param {FormQuestion[]} questions
     */
    const validate = (questions) => {
        const validatableQuestions = getValidatableQuestions(questions)
        const isValid = validateQuestions(validatableQuestions)

        /** @type {string[]} */
        let validatedQuestions = []

        // record validated questions
        if (!isValid) {
            validatedQuestions = validatableQuestions.map(
                (question) => question.guid
            )
        }

        FormDispatch({
            type: 'SET_VALIDATED_QUESTIONS',
            validatedQuestions
        })

        return isValid
    }

    return {
        getCurrentForm,
        onInputChange,
        updateRules,
        saveLabels,
        setQuestionError,
        validate,

        // lifecycle
        resetForm,
        nextStep,
        prevStep,
    }
}

export { useForm }
