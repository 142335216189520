/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import cn from 'clsx'
import { useGridShortAnswerValidator } from 'cms/forms/hooks/use-grid-short-answer-validator'
import { useEffect, useState } from 'react'

/**
 * @param {object} props
 * @param {ShortAnswerSubQuestion} props.subQuestion
 * @param {boolean} props.isTouched
 * @param {string=} props.defaultValue
 * @param {boolean=} props.shouldUpdate - Forces onChange to fire if we've re-enabled the label for an `other` column
 * @param {(value: string) => void} props.onChange
 * @param {(error: string) => void} props.onError
 */
export function GridShortAnswer (props) {

    const {
        subQuestion,
        onChange,
        isTouched,
        onError,
        shouldUpdate = true,
        defaultValue = "",
        placeholder
    } = props

    const [value, setValue] = useState(defaultValue)

    const error = useGridShortAnswerValidator(subQuestion, value)
    const hasError = isTouched && Boolean(error)

    const {
        // max chars
        hasMaxCharacters,
        maxCharacters,
    } = subQuestion

    useEffect(
        () => {
            // ignore until we actually edit this input
            if (value !== undefined && shouldUpdate) {
                onChange(value)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value, shouldUpdate]
    )

    useEffect(
        () => {
            onError(error)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error]
    )

    const className = cn({
        'form-field-error-input': hasError
    })

    const onChangeHandler = (event) => {
        setValue(event.target.value)
    }

    return (
        <input
            className={className}
            type="text"
            value={value}
            onChange={onChangeHandler}
            maxLength={hasMaxCharacters ? Number(maxCharacters) : undefined}
            placeholder={placeholder}
        />
    )
}
