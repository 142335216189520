/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { useForm } from 'cms/forms/context'
import ErrorMsg from '../error-msg'
import { useNumericalConstraints } from '../hooks/use-numerical-constraints'
import { useNumericalValidator } from '../hooks/use-numerical-validator'
import { useSetQuestionErrors } from '../hooks/use-set-question-errors'
import { useShowError } from '../hooks/use-show-error'

/**
 * @type {QuestionComponent<FormQuestion<number>>}
 */
const SimplicityFormNumeric = (props) => {

    const { field } = props || {}
    const {
        guid,
        prompt,
        value,
        hasDescription,
        description,
        hasPlaceholder,
        hasPlaceholderText, // is the text, is not a boolean
        hasHelperText,
        helperText,
        isRequired,

        // min
        hasMinValue,
        minValue,

        // max
        hasMaxValue,
        maxValue,

        // decimals
        hasDecimalValue: _hasDecimalValue,
        decimalValue: _decimalValue,
        hasDemicalValue,
        demicalValue

    } = field || {}

    // Fixes for a typo in data
    const hasDecimalValue = hasDemicalValue ? hasDemicalValue : _hasDecimalValue
    const decimalValue = demicalValue ? demicalValue : _decimalValue

    const { onInputChange } = useForm()

    const { min, max, decimals, incrementStep } = useNumericalConstraints(
        hasDecimalValue,
        decimalValue,
        hasMinValue,
        minValue,
        hasMaxValue,
        maxValue,
    )
    // const formatter = new Intl.NumberFormat('en-US', {
    //     minimumFractionDigits: decimalValue,
    //     maximumFractionDigits: decimalValue,
    // });
    // const precision = Number(decimalValue) || 1
    // const defaultPlaceholder = precision > 0 ? formatter.format(0) : 1
    // const placeholder = hasPlaceholder ? hasPlaceholderText : `e.g. ${defaultPlaceholder}`
    const placeholder = hasPlaceholder ? hasPlaceholderText : undefined
    const currentError = useNumericalValidator(
        min,
        max,
        decimals,
        isRequired,
        value
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    const onChangeHandler = (event) => {
        onInputChange(event.target.value, field)
    }

    return (
        <div className="simplicity-forms-field-group">
            <label className="simplicity-forms-field-label">
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description &&
                <span className="simplicity-forms-description">{description}</span>
            }
            <input
                type='number'
                id={guid}
                name={guid}
                placeholder={placeholder}
                value={value}
                min={min}
                max={max}
                step={incrementStep}
                onChange={onChangeHandler}
                className={hasError ? 'form-field-error-input' : ''}
            />
            {hasError &&
                <ErrorMsg type="error" error={error} />
            }
            {hasHelperText && helperText &&
                <span className="simplicity-forms-helper-text">{helperText}</span>
            }
        </div>
    )
}

export { SimplicityFormNumeric }
